<template>
  <div
    :class="[
      'search',
      type === 'squared' && 'search__squared',
      active && 'search--active',
      filled && 'search--filled',
      disabled && 'search--disabled',
    ]"
  >
    <label
      for="search"
      class="search__icon"
    >
      <svg
        focusable="false"
        viewBox="0 0 24 24"
        class="svg-icon"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9804 15.3946c-1.0361.7502-2.3099 1.1925-3.6869 1.1925C6.8177 16.5871 4 13.7694 4
            10.2935 4 6.8177 6.8177 4 10.2935 4c3.4759 0 6.2936 2.8177 6.2936 6.2935 0 1.377-.4423
            2.6508-1.1925 3.6869l4.6016 4.6016-1.4142 1.4142-4.6016-4.6016zm.6067-5.1011c0
            2.3713-1.9223 4.2936-4.2936 4.2936C7.9223 14.5871 6 12.6648 6 10.2935 6 7.9223 7.9223
            6 10.2935 6c2.3713 0 4.2936 1.9223 4.2936 4.2935z"
        />
      </svg>
    </label>
    <div
      class="search__input-wrapper"
    >
      <input
        id="search"
        ref="inputRef"
        v-model="searchText"
        :disabled="disabled"
        autocomplete="off"
        type="search"
        class="search__input"
        placeholder="Search"
        aria-label="Search"
        @focus="onFocus"
        @blur="onBlur"
      >
      <button
        v-if="searchText"
        type="button"
        class="btn btn--xsmall
        search__btn
        btn--icon-tertiary
        search__action search__clear"
        @click="onCrossClicked(searchText)"
      >
        <span
          class="btn__inner"
        >
          <svg
            focusable="false"
            viewBox="0 0 24 24"
            class="svg-icon btn__icon"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283
                2.8283-2.8283-1.4142-1.4142-2.8283 2.8283L9.172 7.7578 7.7578
                9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"
            />
          </svg>
          <span
            class="btn__label"
          >
            Clear search field
          </span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';

export default {
  props: {
    // Controls search text
    modelValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const searchText = ref(props.modelValue);
    const active = ref(false);
    const filled = computed(() => Boolean(searchText.value.length));
    const inputRef = ref(null);

    watch(() => props.modelValue, (val) => {
      if (val !== searchText.value) {
        searchText.value = val;
      }
    });

    watch(() => searchText.value, () => {
      emit('update:modelValue', searchText.value);
    });

    const onFocus = () => {
      active.value = true;
    };

    const onBlur = () => {
      active.value = false;
    };

    const onCrossClicked = () => {
      emit('update:modelValue', '');
      inputRef.value.focus();
    };

    return {
      searchText,
      active,
      filled,
      inputRef,
      onFocus,
      onBlur,
      onCrossClicked,
    };
  },
};
</script>

<style scoped>
.search__squared {
  height: 2.625rem;
  background-color: var(--colour-bg-default);
  border-color: var(--colour-border-light);
  border-radius: 0.25rem;
}

.search__input {
  padding-right: 2rem;
}

.search__btn {
  padding-right: 0.875rem;
}

.search__squared .search__input {
  padding-right: 1.5rem;
}

.search__squared .search__btn {
  padding-right: 0.375rem;
}

.search--disabled,
.search--disabled input::placeholder {
  color: var(--colour-disabled-ui);
}
</style>
