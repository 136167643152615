import { request } from '@/services/api-utils';

export const getHierarchy = async (hierarchyCode) => request(process.env.VUE_APP_API_HIERARCHY_MANAGEMENT, 'get', '/hierarchy?'
+ `hierarchyCode=${hierarchyCode}`);

export const getHierarchies = async ({
  productCode,
  type,
},
) => request(process.env.VUE_APP_API_SYSTEM_MANAGEMENT, 'get', '/hierarchies?'
+ `productCode=${productCode}`
+ `&type=${type}`);

export const getProductHierarchies = async ({
  productCode,
},
) => request(process.env.VUE_APP_API_HIERARCHY_MANAGEMENT, 'get', '/hierarchies?'
+ `productCode=${productCode}`);

export const getHierarchyNode = async ({
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
},
) => request(process.env.VUE_APP_API_HIERARCHY_MANAGEMENT, 'get', '/hierarchynode?'
+ `hierarchyCode=${hierarchyCode}`
+ `&hierarchyNodeCode=${hierarchyNodeCode}`
+ `&hierarchyNodeType=${hierarchyNodeType}`);

export async function getHierarchyNodes({
  hierarchyCode,
  hierarchyNodeCode,
  hierarchyNodeType,
  ancestors = false,
}) {
  return request(process.env.VUE_APP_API_HIERARCHY_MANAGEMENT, 'get', '/hierarchynodes?'
  + `hierarchyCode=${hierarchyCode}`
  + `&hierarchyNodeCode=${hierarchyNodeCode}`
  + `&hierarchyNodeType=${hierarchyNodeType}`
  + `&ancestors=${ancestors}`);
}
