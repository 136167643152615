/* eslint-disable import/prefer-default-export */
import { useUtilsStore } from '@/store/utils';
import {
  getHierarchies, getHierarchy, getHierarchyNode, getHierarchyNodes, getProductHierarchies,
} from '@/services/hierarchy-management';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const defaultState = {
  hierarchy: undefined,
  hierarchies: [],
  productHierarchies: [],
  hierarchyNode: undefined,
  hierarchyNodes: [],
  hierarchyNodesDict: {},
  searchedForHierarchyNodes: [],
};

export const useHierarchiesStore = defineStore('hierarchies', () => {
  const { compareByProperty } = useUtilsStore();

  const initialState = () => cloneDeep(defaultState);

  const hierarchy = ref(defaultState.hierarchy);
  const hierarchies = ref(defaultState.hierarchies);
  const productHierarchies = ref(defaultState.productHierarchies);
  const hierarchyNode = ref(defaultState.hierarchyNode);
  const hierarchyNodes = ref(defaultState.hierarchyNodes);
  const hierarchyNodesDict = ref(defaultState.hierarchyNodesDict);
  const searchedForHierarchyNodes = ref(defaultState.searchedForHierarchyNodes);

  const sortedHierarchyNodesDict = computed(() => (hierarchyNodesDict?.value[hierarchy.value]
    // eslint-disable-next-line no-use-before-define
    ? sortHierarchyNodesDict(hierarchyNodesDict.value[hierarchy.value])
    : undefined));

  /**
   * Adds parentId, id & displayName to the provided node
   * @param {object} node The node to find ID for.
   * @returns {object} The same node but with additional parentId, id & displayName fields.
   */
  const getNodeWithId = (node) => {
    let parentId;

    if (node.ancestors && node.ancestors.length) {
      if (node.ancestors.length === 1) {
        const { code, type } = node.ancestors[0];
        parentId = `${code};${type}`;
      } else {
        // eslint-disable-next-line arrow-body-style
        parentId = node.ancestors.reduce((acc, current) => {
          return typeof acc === 'string'
            ? `${acc},${current.code};${current.type}`
            : `${acc.code};${acc.type},${current.code};${current.type}`;
        });
      }
    }

    return {
      ...node,
      parentId,
      id: parentId ? `${parentId},${node.code};${node.type}` : `${node.code};${node.type}`,
      displayName: node.name ? `${node.name} (${node.code})` : node.code,
    };
  };

  /**
   * Converts a flat array of hierarchy nodes to a dictionary.
   * @param {String[]} theHierarchyNodes Flat array of hierarchy nodes.
   * @param {string} topName The name of the top level node.
   * @returns {object} The top level node. Use .descendants to access children.
   */
  const convertHierarchyNodesToDictionary = (theHierarchyNodes, topName) => {
    if (!theHierarchyNodes || theHierarchyNodes.length <= 0) {
      return;
    }
    const clonedHierarchieNodes = cloneDeep(theHierarchyNodes);

    // eslint-disable-next-line arrow-body-style
    const idList = clonedHierarchieNodes.map((node) => {
      return getNodeWithId(node);
    });

    const idMapping = idList.reduce((acc, node, i) => {
      acc[node.id] = i;
      return acc;
    }, {});

    let root;
    idList.forEach((node) => {
      if (!node.parentId) {
        root = node;
        return;
      }

      let parent = idList[idMapping[node.parentId]];
      if (!parent) { // if parent is not in the list, create a new root parent (unless already done)
        if (root && root.id === node.parentId) {
          parent = root;
        } else {
          const [code, type] = node.parentId.split(',').pop().split(';');
          parent = {
            code,
            type,
            id: node.parentId,
            parentId: null,
            displayName: topName ? `${topName} (${code})` : code,
          };
          root = parent;
        }
      }
      parent.descendants = [...(parent.descendants || []), node];
    });

    // eslint-disable-next-line consistent-return
    return root;
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeHierarchy = (theHierarchy) => {
    hierarchy.value = theHierarchy;
  };
  const resetHierarchy = () => {
    hierarchy.value = initialState().hierarchy;
  };
  const fetchHierarchy = async (hierarchyCode) => {
    try {
      const payload = await getHierarchy(hierarchyCode);
      hierarchy.value = payload.hierarchy;
      return true;
    } catch (err) {
      console.log('Error in fetchHierarchy:', err, err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeHierarchies = (theHierarchies) => {
    hierarchies.value = theHierarchies;
  };
  const resetHierarchies = () => {
    hierarchies.value = initialState().hierarchies;
  };
  const fetchHierarchies = async (queries) => {
    try {
      const payload = await getHierarchies(queries);
      hierarchies.value = payload.hierarchyCodes;
      return true;
    } catch (err) {
      console.log('Error in fetchHierarchies:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  const fetchProductHierarchies = async (queries) => {
    try {
      const payload = await getProductHierarchies(queries);
      productHierarchies.value = payload.hierarchyCodes;
      return true;
    } catch (err) {
      console.log('Error in fetchProductHierarchies:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeHierarchyNode = (theHierarchyNode) => {
    hierarchyNode.value = theHierarchyNode;
  };
  const resetHierarchyNode = () => {
    hierarchyNode.value = initialState().hierarchyNode;
  };
  const fetchHierarchyNode = async (queries) => {
    try {
      const payload = await getHierarchyNode(queries);
      hierarchyNode.value = payload.hierarchyNode;
      return true;
    } catch (err) {
      console.log('Error in fetchHierarchyNode:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeHierarchyNodes = (theHierarchyNodes) => {
    hierarchyNodes.value = theHierarchyNodes;
  };
  const resetHierarchyNodes = () => {
    const { hierarchyNodes: n, hierarchyNodesDict: d } = initialState();
    hierarchyNodes.value = n;
    hierarchyNodesDict.value = d;
  };

  /**
   * Will fetch a list of all nodes in the hierarchy.
   * Automatically converts it into a hierarchial dictionary/tree.
   * Access dictionary through "hierarchyNodesDict".
   * @param {string} queries - The hierarchy to fetch nodes for.
   * @returns {boolean} True if successful, false if error.
   */
  const fetchHierarchyNodes = async (queries) => {
    try {
      console.log('Queries', queries);
      const nodes = await getHierarchyNodes(queries); // ...since this will only get ancestors or descendants.
      const node = await getHierarchyNode({
        ...queries,
        hierarchyNodeCode: queries.hierarchyNodeCode || nodes.hierarchyNodes[0].ancestors[0]?.code || nodes.hierarchyNodes[0].code,
        hierarchyNodeType: queries.hierarchyNodeType || 'ROOTNODE',
      });
      const { name } = node.hierarchyNode;
      hierarchyNodes.value[queries.hierarchyCode] = nodes.hierarchyNodes;
      hierarchyNodesDict.value[queries.hierarchyCode] = convertHierarchyNodesToDictionary(nodes.hierarchyNodes, name);
      // If the selected hierarchy node has no children
      if (queries.hierarchyCode && !hierarchyNodesDict.value[queries.hierarchyCode]) {
        hierarchyNodesDict.value[queries.hierarchyCode] = {
          ...node.hierarchyNode, displayName: `${node.hierarchyNode.name} (${node.hierarchyNode.code})`,
        };
      }
      return true;
    } catch (err) {
      console.error('Error in fetchHierarchyNodes:', err, err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };
  const queryHierarchyNodes = (searchText) => {
    const res = hierarchyNodes.value[hierarchy.value].filter((node) => (node.name.toLowerCase()
      .includes(searchText.toLowerCase())) || node.code.toLowerCase()
      .includes(searchText.toLowerCase()));
    return res;
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeHierarchyNodesDict = (theHierarchyNodesDict) => {
    hierarchyNodesDict.value = theHierarchyNodesDict;
  };
  const resetHierarchyNodesDict = (theHierarchyToReset = 'all') => {
    if (theHierarchyToReset === 'all') {
      hierarchyNodesDict.value = initialState().hierarchyNodesDict;
    } else if (hierarchyNodesDict[theHierarchyToReset]) {
      delete hierarchyNodesDict[theHierarchyToReset];
    }
  };

  /**
   * Recursively traverses through the node's descendants to find the node with the provided id.
   * @param {*} node - Node object.
   * @param {*} id - The id of the node to find.
   * @returns {object|undefined} - The node object if it's found, otherwise undefined if node doesn't exist.
   */
  const findNodeInNodesDict = (node, id) => {
    let theNode;
    if (node) { // TODO Double-check
      theNode = node;
    }
    if (theNode?.id === id) {
      return theNode;
    // eslint-disable-next-line no-else-return
    } else if (theNode?.descendants
      && theNode.descendants.length) {
      let result;
      let index;
      // eslint-disable-next-line consistent-return
      // eslint-disable-next-line no-plusplus
      for (index = 0; result == null && index < theNode.descendants.length; index++) {
        result = findNodeInNodesDict(theNode.descendants[index], id);
      }
      return result;
    }

    return undefined;
  };

  /**
   * Queries the the hierarchy node tree for the specific node.
   * @param {string} hierarchyCode - For example "CON-CTY-STO"
   * @param {string} id - For example "ROOT;ROOTNODE,EUR;CON,SE;CTY,109;STO"
   * @param {object} [node=undefined] - If provided, will only search the partial tree under this node and ignore the value of hierarchyCode.
   * @returns {object|undefined|boolean} - Node object when found, undefined when node doesn't exists in node tree
   * or false if node tree for hierarchy does not exist (in which case use "fetchHierarchyNodes")
   */
  const queryHierarchyNodesDict = (hierarchyCode, id, node = undefined) => {
    /** If node is not set and hierarchy node tree doesn't exist, there is nothing to do. */
    if (!node && !hierarchyNodesDict.value[hierarchyCode]) {
      return false;
    }
    /** If node is set, use it. */
    if (node) {
      return findNodeInNodesDict(node, id);
    }
    /** Else just use the rootnode of the hierarchy. */
    return findNodeInNodesDict(hierarchyNodesDict.value[hierarchyCode], id);
  };

  const sortHierarchyNodesDict = (theNode) => {
    if (theNode.descendants?.length) {
      // Sort the descendants.
      const descendants = theNode.descendants.sort(compareByProperty('displayName'));

      // Loop the descendants call myself recursively.
      descendants.forEach((descendant) => {
        sortHierarchyNodesDict(descendant);
      });

      return {
        ...theNode,
        descendants,
      };
    }

    return theNode;
  };

  /**
   * @deprecated Use the state param directly instead of the store function.
   */
  const storeSearchedForHierarchyNodes = (theSearchedForHierarchyNodes) => {
    searchedForHierarchyNodes.value = theSearchedForHierarchyNodes;
  };
  const resetSearchedForHierarchyNodes = () => {
    searchedForHierarchyNodes.value = initialState().searchedForHierarchyNodes;
  };

  return {
    hierarchy,
    storeHierarchy,
    fetchHierarchy,
    resetHierarchy,
    hierarchies,
    storeHierarchies,
    fetchHierarchies,
    resetHierarchies,
    productHierarchies,
    fetchProductHierarchies,
    hierarchyNode,
    storeHierarchyNode,
    fetchHierarchyNode,
    resetHierarchyNode,
    hierarchyNodes,
    storeHierarchyNodes,
    fetchHierarchyNodes,
    resetHierarchyNodes,
    queryHierarchyNodes,
    hierarchyNodesDict,
    storeHierarchyNodesDict,
    resetHierarchyNodesDict,
    queryHierarchyNodesDict,
    sortedHierarchyNodesDict,
    searchedForHierarchyNodes,
    storeSearchedForHierarchyNodes,
    resetSearchedForHierarchyNodes,
    getNodeWithId,
    getHierarchyNodes,
  };
});
